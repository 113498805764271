import React from 'react';
import styled from 'styled-components';

import Header from '../components/header';
import Newsletter from '../components/newsletter';
import Footer from '../components/footer';

import GlobalStyle from '../assets/globalStyles';

const Container = styled.div`
  background: #ffffff;
  text-align: center;
  padding: 130px 0;
  h1 {
    font: bold italic 36px 'Times new roman';
  }
`

export default function NotFound() {
  return (
    <>
      <Header />
      <Container>
        <div className="wrapper">
          <h1>Página não encontrada</h1>
          <p>Esse link nunca existiu ou não existe mais :(</p>
        </div>
      </Container>
      <Newsletter />
      <Footer />

      <GlobalStyle />
    </>
  );
}